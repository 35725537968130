@custom-blue: #31b0d5;
@custom-gray: #b3b3b3;
@custom-orange: #eb661b;
@custom-black: #1a1a1a;

body {
    color: @custom-black;
}

#content-header {
    padding-top: 24px;
    padding-bottom: 30px;
    background: @custom-blue url(++theme++ziskej-info-theme/images/header-line.png) repeat-x left bottom;
}

#portal-header,
#portal-searchbox {
    margin: 0;
}

#portal-searchbox {
    margin-top: 18px;

    .searchHeading {
        font-size: 16px;
    }

    .searchSection {
        display: none;
    }
}

#portal-anontools {
    margin: 24px 18px 0 0;


    ul {
        margin: 0;
        padding: 0;

        li {
            margin: 0;
            padding: 0;

            a {
                color: @custom-black;
                font-weight: 700;
                font-size: 16px;
            }
        }
    }
}

.plone-navbar {
    background-color: transparent;
    box-shadow: none;
}

.plone-navbar-nav {
    & > li {
        &:first-child {
            & > a {
                padding-left: 0;
            }
        }

        &:last-child {
            & > a {
                padding-right: 0;
            }
        }

        & > a {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }

    li {
        a {
            text-transform: uppercase;
            color: @custom-black;
            font-weight: 300;
        }

        &:hover,
        &.selected {
            background-color: transparent;

            a {
                color: @custom-black;
                background-color: transparent;
                font-weight: 700;
            }
        }

        &.selected {
            &:hover {
                a {
                    color: @custom-black;
                    background-color: transparent;
                }
            }
        }
    }
}

#portal-searchbox [type="submit"] {
}

#content-core {
    a {
        color: @custom-blue;
        text-decoration: none;
        border: none;

        &:hover,
        &:active {
            color: @custom-blue;
            text-decoration: underline;
        }
    }

    p {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
    }
}

.documentFirstHeading {
    border: none;
}

h2,
h3 {
    color: @custom-orange;
}

.plone-breadcrumb {
    background: none;

    ol {
        li {
            & + li {
                &:before {
                    content: '/';
                    border: none;
                    top: 50%;
                    transform: translateY(-50%);
                    color: @custom-gray;
                }

                &:after {
                    display: none;
                }
            }
        }

        a {
            color: @custom-blue;
        }
    }
}

#portal-footer-wrapper {
    color: @custom-black;
    background: @custom-gray;

    a {
        color: @custom-black;
        text-decoration: underline;

        &:hover,
        &:active {
            color: @custom-black;
            text-decoration: none;
        }
    }

    .glyphicon,
    abbr {
        color: @custom-black;
    }

    #portal-footer > .row {
        padding: 0;
        margin: 0;
    }
}

.portlet {
    border-color: @custom-gray;

    .portletHeader {
        //background: @custom-gray;
        //color: @custom-black;

        > a {
            color: @custom-black;
            text-decoration: underline;

            &:hover,
            &:active {
                color: @custom-black;
                text-decoration: none;
            }
        }
    }

    .portletContent {
        border-top-color: @custom-gray;

        > ul {
            > li {
                a {
                    color: @custom-blue;
                    border-top-color: @custom-gray;
                }
            }
        }
    }

    .portletFooter {
        border-top-color: @custom-gray;

        a {
            color: @custom-blue;
        }
    }
}

.documentDescription {
    color: @custom-orange;
}
